import React from 'react'
import { Grid } from '@mui/material'
import anos from './lambo.png'
import uni from './ray.png'
import dex from './ds.png'



function Desktop() {
  return (
    <div className='container'>
      <br/>
      <Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">WEN LAMBO</h1>
         </Grid>
    

       
          </Grid>
      

          <Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>
                <img src={anos} className='image_anos'/>
         </Grid>
          </Grid>

          <br/>  <br/>


       

          <br/>  <br/>


          
          <Grid container>
          <Grid item md={6} xs={6} lg={6} className='centerall'>
            <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=9W1qKyy15Rri6kxc4Fu891kJYqvRWMuBqKpF2DRhG4tK&fixed=in'>   <img src={uni} className='image_anos_uni'/></a>
       
          <br/>  <br/>

        
         </Grid>
         <Grid item md={6} xs={6} lg={6} className='centerall'>
          <a href='https://dexscreener.com/solana/9w1qkyy15rri6kxc4fu891kjyqvrwmubqkpf2drhg4tk'>   <img src={dex} className='image_anos_dex'/></a>
       
          <br/>  <br/>
      
         </Grid>
          </Grid>


          <br/>  <br/>


          
<Grid container id='Tokenomics'>
<Grid item md={12} xs={12} lg={12} className='centerall'>
          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">Contract Address</h1>
         </Grid>
    
         <Grid item md={12} xs={12} lg={12} className='centerall'>
          <p className='CA cas'> 9W1qKyy15Rri6kxc4Fu891kJYqvRWMuBqKpF2DRhG4tK</p>
         </Grid>


</Grid>





<br/>  <br/>
<br/>  <br/>
    </div>
  )
}

export default Desktop