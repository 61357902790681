import React from 'react'

import { Grid } from '@mui/material'
function Navbar() {
  return (
    <div className='containerx'>
       <div className='container'>    <br/>
              <Grid container spacing={2}>
              <Grid item lg={2.4} md={2.4} xs={6} sm={6} className='centeritall'>
                <a href='https://twitter.com/LamboSol69' target='_blank'>              <button>
 Twitter
</button></a>


                
</Grid>
<Grid item lg={2.4} md={2.4} xs={6} sm={6} className='centeritall'>
<a href='https://t.me/lambosol69' target='_blank'><button>
 Telegram
</button></a>

                
</Grid>
<Grid item lg={2.4} md={2.4} xs={6} sm={6} className='centeritall'>
<a href='https://solscan.io/token/9W1qKyy15Rri6kxc4Fu891kJYqvRWMuBqKpF2DRhG4tK' target='_blank'><button>
Solscan
</button></a>

                
</Grid>
<Grid item lg={2.4} md={2.4} xs={6} sm={6} className='centeritall'>
<a href='https://dexscreener.com/solana/9w1qkyy15rri6kxc4fu891kjyqvrwmubqkpf2drhg4tk ' target='_blank'><button>
Dexscreener
</button></a>

                
</Grid>
<Grid item lg={2.4} md={2.4} xs={12} sm={12} className='centeritall'>
<a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=9W1qKyy15Rri6kxc4Fu891kJYqvRWMuBqKpF2DRhG4tK&fixed=in ' target='_blank'><button>
Buy $Lambo
</button></a>

                
</Grid>
              </Grid> <br/></div>
  

    </div>
  )
}

export default Navbar