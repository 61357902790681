import React, { useState } from "react";
import musicFile from "./noot.mp3";
import { Modal} from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Navbar from "./NavBar/Navbar";
import Desktop from "./Desktop";
import Desktopthree from "./Desktopthree";

import Mobile from "./Mobile";

import Mobilethree from "./Mobilethree";
import AnimatedCursor from "react-animated-cursor"

import boot from './bot.png'


const Home = () => {
  const [showModal, setShowModal] = useState(true);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));


  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();
  };



  return (
    <div className="mainContent">


<div className="garden">
      {/* */}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="desk">
          <div className="desks">
            <Navbar/>
        <Desktop/>
        <Desktopthree/>
        <AnimatedCursor />
        </div>
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
         <div className="mob">
           <div className="desks">
            <Navbar/>
         <Mobile/>
  
         <Mobilethree/>
     
       </div> </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
     
            {/*  <>  
    <Modal
     backdrop="static"
        show={showModal}
       
        keyboard={false}
        onHide={() => {}}
        className=""
      >
        <
        >
    
     
     
        <div className="sunken-panel w-full ">
        <>
        <br/>
        <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center token_text doyou" >Welcome Pengu
</h1>


<img  src={boot} style={{width:"100%"}}/>
        </>
      
        <div className='centerall'>
        <br/>
        <button class='glowing-btn'   onClick={() => {
              handleYesClick();
              setShowModal(false);
            }}><span class='glowing-txt'> <span class='faulty-letter'>Noot</span>Noot</span></button>
      
        </div>
        <br/>
    </div>
        <>
        <div >        <  >
        
        </> </div>
        
        </>
   
  
        </>
  
      </Modal>
      
      </>*/}
   
   
   
    </div>
  );
};

export default Home;
